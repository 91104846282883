<template lang="pug">
v-container(fluid)
    v-row(style="")
        v-col(cols="12")
            v-data-table(
                :headers="headers"
                :items="localPoints"
                item-key="id"
                :style="{ width: '100%' }"
                class="custom-table"
            )

                template(v-slot:item.standard_uom_id="{ item }")
                    | {{ getUomName(item.standard_uom_id) }}
                template(v-slot:item.is_finalized="{ item }")
                    v-icon(
                        :color="item.is_finalized ? 'green' : 'red'"
                    )
                        | {{ item.is_finalized ? 'check' : 'close' }}
                template(v-slot:item.actions="{ item }")
                    v-icon(@click="editPoint(item)") edit
                    v-icon(@click="deletePoint(item)") delete
                template(v-slot:top)
                    v-toolbar(flat)
                        v-spacer
                        v-dialog(
                            v-model='editDialog'
                            max-width='550px'
                            light
                        )
                            v-card(light)
                                v-card-title
                                    span.text-h5 Edit Point
                                v-card-subtitle
                                    span {{ editedItem.point_name }}
                                v-card-text
                                    v-form(
                                        ref="pointEditForm"
                                        v-model="valid"
                                    )
                                        v-container
                                            v-row
                                                v-col(cols="12" sm="6" md="4")
                                                    v-text-field(
                                                        v-model.trim="editedItem.source_uom"
                                                        label="UOM (sys)"
                                                        :rules="[rules.required]"
                                                        readonly
                                                        color="brand"
                                                    )
                                                v-col(cols="12" sm="6" md="4")
                                                    v-select(
                                                        v-model="editedItem.standard_uom_id"
                                                        label="UOM (std)"
                                                        :items="filteredUnits"
                                                        item-text="uom_code"
                                                        item-value="id"
                                                        :rules="[rules.required]"
                                                        color="brand"
                                                    )
                                            v-row
                                                v-col(cols="12" sm="6" md="4")
                                                    v-select(
                                                        v-model="editedItem.container_type"
                                                        :items="['NONE', 'BOX', 'CYLINDER', 'SPHERE']"
                                                        label="Container Type"
                                                        color="brand"
                                                        @change="handleContainerTypeChange"
                                                    )
                                                template(v-if="editedItem.container_type !== 'NONE'")
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-select(
                                                            v-model="editedItem.container_dimensions_uom_id"
                                                            :items="linearUnits"
                                                            item-text="uom_code"
                                                            item-value="id"
                                                            label="Dimensions UOM"
                                                            :rules="[rules.required]"
                                                            color="brand"
                                                        )
                                            v-row
                                                //- Container dimensions based on type
                                                template(v-if="editedItem.container_type === 'BOX'")
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model.number="editedItem.container_dimensions.length"
                                                            label="Length"
                                                            type="number"
                                                            color="brand"
                                                            :rules="[rules.required, rules.gtZero]"
                                                        )
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model.number="editedItem.container_dimensions.width"
                                                            label="Width"
                                                            type="number"
                                                            color="brand"
                                                            :rules="[rules.required, rules.gtZero]"
                                                        )
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model.number="editedItem.container_dimensions.height"
                                                            label="Height"
                                                            type="number"
                                                            color="brand"
                                                            :rules="[rules.required, rules.gtZero]"
                                                        )
                                                template(v-if="editedItem.container_type === 'CYLINDER'")
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model.number="editedItem.container_dimensions.radius"
                                                            label="Radius"
                                                            type="number"
                                                            color="brand"
                                                            :rules="[rules.required, rules.gtZero]"
                                                        )
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model.number="editedItem.container_dimensions.height"
                                                            label="Height"
                                                            type="number"
                                                            color="brand"
                                                            :rules="[rules.required, rules.gtZero]"
                                                        )
                                                template(v-if="editedItem.container_type === 'SPHERE'")
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model.number="editedItem.container_dimensions.radius"
                                                            label="Radius"
                                                            type="number"
                                                            color="brand"
                                                            :rules="[rules.required, rules.gtZero]"
                                                        )
                                            v-row
                                                template(v-if="editedItem.is_finalized")
                                                    v-col(cols="12" sm="6" md="7")
                                                        v-text-field(
                                                            v-model="editedItem.finalized_by"
                                                            label="Finalized By"
                                                            readonly
                                                            color="brand"
                                                            disabled
                                                        )
                                                    v-col(cols="12" sm="6" md="5")
                                                        v-text-field(
                                                            v-model="formattedFinalizationDate"
                                                            label="Finalized On"
                                                            readonly
                                                            color="brand"
                                                            disabled
                                                        )
                                v-card-actions
                                    v-btn(
                                        @click="finalizePoint"
                                        color="red darken-1"
                                        :disabled="!valid || editedItem.is_finalized"
                                        text
                                    ) Finalize
                                    v-btn(
                                        @click="unFinalizePoint"
                                        :disabled="!editedItem.is_finalized"
                                        color="orange darken-1"
                                        text
                                    ) Unfinalize
                                    v-spacer
                                    v-btn(
                                        color="blue darken-1"
                                        text
                                        @click="closeDialog"
                                    ) Cancel
                                    v-btn(
                                        color="blue darken-1"
                                        text
                                        @click="savePoint"
                                        :disabled="!valid"
                                    ) Save
        v-dialog(
            v-model="deleteDialog"
            max-width="500px"
        )
            v-card(light style="width: 100%;")
                v-card-title.text-h5 Delete Point
                v-card-text Are you sure you want to delete this point?
                v-card-actions
                    v-spacer
                    v-btn(
                        color="blue darken-1"
                        text
                        @click="closeDeleteDialog"
                    ) Cancel
                    v-btn(
                        color="red darken-1"
                        text
                        @click="deletePointConfirm"
                    ) Delete
</template>

<script lang="js">

import mergeDeep from 'lodash/merge';

export default {
    name: 'Point',
    emits: ['update:points'],
    props: {
        category: {
            type: Object,
            required: true
        },
        uom: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            localPoints: mergeDeep([], this.category.points),
            headers: [
                { text: 'Point Name', value: 'point_name'},
                { text: 'UOM', value: 'standard_uom_id' },
                { text: 'Status', value: 'server_status' },
                { text: 'Finalized', value: 'is_finalized' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            editDialog: false,
            valid: false,
            rules: {
                required: (value) => !!value || 'Required.',
                gtZero: (value) => value > 0 || 'Value must be greater than 0.'
            },
            defaultPoint: {
                container_dimensions: {
                    length: null,
                    width: null,
                    height: null,
                    radius: null
                },
                container_type: 'NONE',
                finalized_by: null,
                finalized_date: null,
                is_finalized: false,
                point_id: null,
                point_name: '',
                source_uom: '',
                standard_uom_id: null,
            },
            editedItem: {},
            deleteDialog: false,
            itemToDelete: null,
        };
    },
    watch: {
        category: {
            handler (newVal) {
                // use vue reactive
                this.localPoints = mergeDeep([], newVal.points);
            },
            deep: true,
            immediate: true
        },
        editedItem: {
            handler () {
                if(this.$refs['pointEditForm']) {
                    this.$refs.pointEditForm.resetValidation();
                    this.$refs.pointEditForm.validate();
                    if(!this.valid && this.editedItem.is_finalized) {
                        this.unFinalizePoint();
                    }
                }
            },
            deep: true
        }
    },
    computed: {
        formattedFinalizationDate () {
            if (!this.editedItem.finalized_date) return '';
            return new Date(this.editedItem.finalized_date).toLocaleString();
        },
        linearUnits () {
            return this.uom.filter(unit => unit.type === 'LINEAR');
        },
        volumeUnits () {
            return this.uom.filter(unit => unit.type === 'VOLUME');
        },
        nonLinearUnits () {
            return this.uom.filter(unit => unit.type === 'VOLUME' || unit.type === 'RATE');
        },
        filteredUnits () {
            return this.editedItem.container_type === 'NONE' ? this.nonLinearUnits : this.volumeUnits;
        }
    },
    methods: {
        emitPointsUpdate () {
            this.$emit('update:points', {
                category_id: this.category.id,
                points: mergeDeep([], this.localPoints)
            });
        },

        getUomName (uom_id) {
            const uom = this.uom.find(uom => uom.id === uom_id);
            return uom ? uom.uom_code : 'Unknown UOM';
        },

        handleContainerTypeChange () {
            // Reset dimensions when container type changes
            this.editedItem.container_dimensions = {
                length: null,
                width: null,
                height: null,
                radius: null
            };
        },
        editPoint (item) {
            this.editedItem = {
                ...item,
                container_dimensions: item.container_dimensions || {
                    length: null,
                    width: null,
                    height: null,
                    radius: null
                }
            };
            this.editDialog = true;
        },
        deletePoint (item) {
            this.itemToDelete = item;
            this.deleteDialog = true;
        },

        closeDeleteDialog () {
            this.deleteDialog = false;
            this.itemToDelete = null;
        },

        deletePointConfirm () {
            const index = this.localPoints.findIndex(point => point.id === this.itemToDelete.id);
            if (index > -1) {
                this.$set(this.localPoints, index, undefined);
                this.localPoints.splice(index, 1);
            }

            this.emitPointsUpdate();
            this.closeDeleteDialog();
        },
        closeDialog () {
            this.editDialog = false;
        },

        savePoint () {
            console.log('Saving point:', this.editedItem);

            if (this.editedItem.id) {
                const index = this.localPoints.findIndex(pt => pt.id === this.editedItem.id);
                if (index > -1) {
                    console.log('-- Updating existing point');
                    this.$set(this.localPoints, index, mergeDeep({}, { ... this.editedItem}));
                    this.emitPointsUpdate();
                } else {
                    console.error(' -- Point not found for update');
                }
            }

            this.closeDialog();

        },

        finalizePoint () {
            if (!this.editedItem.is_finalized && this.valid) {
                this.editedItem.is_finalized = true;
                this.editedItem.finalized_by = this.$store.getters.userEmail;
                this.editedItem.finalized_date = new Date();
            }

            // Update point without closing dialog
            if (this.editedItem.id) {
                const index = this.localPoints.findIndex(pt => pt.id === this.editedItem.id);
                if (index > -1) {
                    this.$set(this.localPoints, index, {...this.editedItem});
                    this.emitPointsUpdate();
                }
            }
        },
        unFinalizePoint () {
            console.log('unfinalizePoint');
            if (this.editedItem.is_finalized) {
                this.editedItem.is_finalized = false;
                this.editedItem.finalized_by = null;
                this.editedItem.finalized_date = null;

                // Update point without closing dialog
                if (this.editedItem.id) {
                    const index = this.localPoints.findIndex(pt => pt.id === this.editedItem.id);
                    if (index > -1) {
                        this.$set(this.localPoints, index, {...this.editedItem});
                        this.emitPointsUpdate();
                    }
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
header {
  margin: 0 !important; /* Remove any margin */
  padding: 0 !important; /* Remove any padding */
  height: auto !important; /* Allow height to adjust */
}
</style>
