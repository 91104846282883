<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols="3")
            v-card(outlined)
                v-card-title Categories
                v-card-subtitle
                    span requires 4 finalized (green check) categories
                v-list
                    v-list-item(
                        v-for="category in localCategories"
                        :key="category.id"
                        @click="selectCategory(category)"
                        :class="{ 'selected-category': selectedCategory && selectedCategory.id === category.id}"
                    )
                        v-list-item-content
                            v-list-item-title(class="small-font") {{ category.category_name }}
                                v-icon(:color="category.is_finalized ? 'green' : 'red'" class="ml-4")
                                     | {{ category.is_finalized ? 'check' : 'close' }}
        v-col(cols="9")
            v-card(v-if="selectedCategory" outlined)
                v-card-title Edit {{ selectedCategory.category_name }}
                v-card-text
                    v-row
                        v-col
                            v-form(ref="categoryForm")
                                v-row
                                    v-col(cols="12" sm="8" md="3")
                                        v-select(
                                            label="UOM"
                                            v-model="selectedCategory.common_uom_id"
                                            :items="localUom"
                                            item-text="uom_code"
                                            item-value="id"
                                            :rules="[rules.required, value => isValidCategoryUom(value)]"
                                            required
                                            color="brand"
                                        )
                                    v-col
                                        v-spacer
                    v-row(v-if="selectedCategory.is_finalized" dense)
                        v-col(cols="12" sm="8" md="6")
                            v-text-field(
                                v-model="selectedCategory.finalized_by"
                                label="Finalized By (read-only)",
                                readonly
                                color="brand"
                            )
                        v-col(cols="12" sm="6" md="4")
                            v-text-field(
                                :value="formattedFinalizedDate"
                                label="Finalized On (read-only)",
                                readonly
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12")
                            Points(
                                :category="selectedCategory",
                                :uom="localUom",
                                @update:points="handleUpdatePoints"
                            )

                v-card-actions
                    template(v-if="selectedCategory.is_finalized")
                        v-btn(
                            color="orange"
                            @click="unFinalizeCategory"
                            class="ml-5"
                        ) UnFinalize
                    template(v-if="selectedCategory.canFinalize && !selectedCategory.is_finalized")
                        v-btn(
                            color="green"
                            @click="finalizeCategory"
                        ) Finalize
                    v-spacer
                    v-tooltip(top)
                        template(v-slot:activator="{ on }")
                            v-btn(
                                color="brand"
                                @click="saveCategory"
                                class="white--text mr-1"
                                :disabled="!isValid"
                                v-on="on"
                            ) Save
                        span Save Category Locally
                    v-btn(
                        color="gray"
                        @click="closeConfig"
                        class="mr-5"
                    ) Close

            v-card(v-else)
                v-card-title No Configuration Possible
                v-card-text Waiting for the Analytics Client to send points


</template>

<script>

// Only system save should write to the server
// all other changes should be local and then to the store state
// An over all save button will then run a store action to save to the server

import mergeDeep from 'lodash/merge';
import Points from './Points.vue';

export default {
    name: 'Categories',
    emits: ['update:categories', 'close:categories'],
    components: {
        Points
    },
    props: {
        categories: {
            type: Array,
            required: true
        },
        systemName: {
            type: String,
            required: true
        },
        systemId: {
            type: String,
            required: true
        },
        uom: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            selectedCategory: {},
            selectedPredictionCategory: null,
            // openPoints: false,
            localCategories: mergeDeep([], this.categories).map(cat => {
                cat.valid = false;
                return cat;
            }),
            localUom: [...this.uom],
            newItem: null,
            rules: {
                required: (value) => !!value || 'Required.',
            },
        };
    },
    methods: {
        selectCategory (category) {
            this.selectedCategory = Object.assign({}, category);
            this.updateFlagsInSelectedCategory();
        },

        closeConfig () {
            this.$emit('close:categories');
        },

        getUomName (id){
            const uom = this.localUom.find(u => u.id === id);
            return uom ? uom.uom_code : '';
        },

        noDuplicate (value, currentCategoryName) {
            const exists = this.localCategories.find(cat => cat.category_name === value && cat.category_name !== currentCategoryName);
            return !exists || 'Category already exists';
        },

        saveCategory () {
            if(this.selectedCategory.common_uom_id > 1){
                const index = this.localCategories.findIndex(cat => cat.id === this.selectedCategory.id);
                this.$set(this.localCategories, index, mergeDeep({}, this.selectedCategory));

                // update the store, via parent component
                this.$emit('update:categories', mergeDeep([], this.localCategories));
            }
        },

        updateFlagsInSelectedCategory () {
            const somePointsFinalized = this.selectedCategory.points.some(point => point.is_finalized) || false;
            const valid = this.selectedCategory.common_uom_id > 1;
            const isFinalized = Boolean( this.selectedCategory.is_finalized );

            const canFinalize = !isFinalized && valid && somePointsFinalized;
            this.$set(this.selectedCategory, 'canFinalize', canFinalize);

            this.$set(this.selectedCategory, 'valid', valid);
        },

        /**
         *
         * @param {object} payload
         * @param {object} payload.category_id
         * @param {{}[]} payload.points
         */
        handleUpdatePoints (payload) {
            // find the index of our point in this.localCategories
            console.log('Categories >> Updating points:', payload);
            const catIndex = this.localCategories.findIndex(cat => cat.id === payload.category_id);
            this.$set(this.localCategories[catIndex], 'points', payload.points);
            // update points in the selectedCategory
            this.$set(this.selectedCategory, 'points', payload.points);

            //update the canfinalize flag in the category
            const somePointsFinalized = this.localCategories[catIndex].points.some(point => point.is_finalized) || false;
            const canFinalize = this.localCategories[catIndex].common_uom_id > 1 && somePointsFinalized;

            // update the store, via parent component
            this.$emit('update:categories', mergeDeep([], this.localCategories));

            //
            this.updateFlagsInSelectedCategory();
        },

        /**
         * @param {object} category
         */
        finalizeCategory (category) {

            this.$set(this.selectedCategory, 'is_finalized', true);
            this.$set(this.selectedCategory, 'finalized_by', this.$store.state?.fullUser?.email || 'Unknown');
            this.$set(this.selectedCategory, 'finalized_date', new Date().toISOString());
            console.log('Finalizing category:');
            this.saveCategory(this.selectedCategory);
        },

        /**
         */
        unFinalizeCategory () {
            if(this.selectedCategory.is_finalized) {
                this.$set(this.selectedCategory, 'is_finalized', false);
                this.$set(this.selectedCategory, 'finalized_by', null);
                this.$set(this.selectedCategory, 'finalized_date', null);
                this.saveCategory(this.selectedCategory);
            }
        },

        isValidCategoryUom (uom_id) {
            const units = this.uom.filter(unit => unit.type === 'RATE' || unit.type === 'VOLUME');
            const isValid = units.some(unit => unit.id === uom_id);
            if (!isValid) {
                return `Unit of measure must be of type RATE or VOLUME`;
            }
            return true;
        },
    },
    computed: {
        categoryNames () {
            return this.localCategories.map(category => ({
                category_name: category.category_name,
                id: category.id,
            }));
        },
        formattedFinalizedDate () {
            return this.selectedCategory.finalized_date
                ? new Date(this.selectedCategory.finalized_date).toLocaleString()
                : '';
        },
        isValid () {
            const category = this.selectedCategory;
            return category.common_uom_id > 1;
        },

    },
    watch: {
        categories: {
            handler (newVal) {
                console.log('Categories changed:');
                if(newVal){
                    this.localCategories = mergeDeep([], this.categories).map(cat => {
                        cat.valid = false;
                        return cat;
                    });
                }
            },
            immediate: true,
            deep: true
        },
        uom (newVal) {
            this.localUom = [...newVal];
        },
    },
    mounted () {
        this.localCategories = mergeDeep([], this.categories);
    },

    created () {
        if (this.categories.length > 0) {
            this.selectedCategory = { ...this.categories[0] };
        }
    },
};
</script>

<style scoped>
.v-list-item selected-category {
  background-color: #e3f2fd;
}
.small-font {
    font-size: 14px;
}
</style>
